import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/app.constants';
import { Agency, TaxHist } from '@core/models';
import { LabelTax } from '@core/models/Label-tax.model';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { AddressFormComponent } from '@shared/components/address';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ta-default-tax-form',
  templateUrl: './default-tax-form.component.html',
  providers: [AddressFormComponent ],
})
export class DefaultTaxFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  labelColumnCssClasses = 'col-lg-3';
  fieldColumnCssClasses = 'col-lg-9';
  @Input() newInfo: Agency;
  @Input() labelsTax$: Observable<LabelTax[]>;
  @Input() pendingFieldsCard: string[];
  @Output() closeModalFromChild = new EventEmitter<string>();

  isTax1ValueRegistered = false;

  showACNLink = false;
  showSIRENLink = false;
  taxOptions = [];
  selectedTax = '';

  tinHist: TaxHist[];

  taxForm: FormGroup;

  subscription: Subscription;
  countryCodeSelected: string;

  isValidVat: boolean;
  isValidTax: boolean;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private commonService: CommonDataService,
    private profileService: TaprofileUtilsService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit() {

    this.initForm();

    this.subscription = new Subscription();

    this.subscription.add(this.profileService.getSelectedLegalCountryCode().subscribe(countryCode => {
      this.isTax1ValueRegistered = false;
      this.isValidVat = true;
      this.isValidTax = true;
      this.showACNLink = false;
      this.showSIRENLink = false;

      this.countryCodeSelected = countryCode;

      this.resetTax1Value();
      this.setTax1Label();
      this.setTax1Value();

      this.disableSelectTax();
    }));

    this.disablePendingFields();

    this.subscription.add(this.profileService.isValidVat$.subscribe(isValidVat => {
      this.isValidVat = isValidVat;
    }));

    this.subscription.add(this.profileService.isValidTax$.subscribe(isValidTax => {
      this.isValidTax = isValidTax;
    }));

  }

  ngOnDestroy() {
    this.profileService.setIsValidVat(true);
    this.profileService.setIsValidTax(true);
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.taxForm = this.fb.group({
      tax1: [''],
      tax1Label: [''],
      taxSelect: ['', Validators.required],

    });
  }

  get tax1() { return this.taxForm.get('tax1'); }
  get tax1Label() { return this.taxForm.get('tax1Label'); }
  get taxSelect() { return this.taxForm.get('taxSelect'); }


  /**
   * Checks if the field is invalid
   *
   * @param formControl the field to be validated
   * @returns true if the field invalid
   */
  isInvalid(formControl: AbstractControl): boolean {
    if (formControl.errors) {
      return formControl.invalid;
    }
    return false;
  }
  /*
  * Send error message if the field is invalid
  */
  isInvalidMessage(formControl: AbstractControl, alert: string): string {
    if (formControl.invalid) {
      return this.translate.instant('forms.requiredTaxOrVat');
    }
  }

  /** TAX1 */
  setTax1Value() {
    if (this.newInfo && this.newInfo.legal) {
      const vat1: string = this.newInfo.legal.primaryTax;
      if (vat1 && this.newInfo.legal.countryCode === this.countryCodeSelected) {
        this.tax1.patchValue(vat1);
        if (this.tax1.value) {
          this.isTax1ValueRegistered = true;
        }
      } else {
        this.tax1.patchValue(null);
        this.isTax1ValueRegistered = false;
      }
    }
  }

  resetTax1Value() {
    this.tax1.patchValue(null);
    this.tax1Label.patchValue(null);
  }

  setTax1Label() {
    this.tax1Label.patchValue(null);

    this.commonService.labelTax.subscribe(labelsTax => {
      if (labelsTax[this.countryCodeSelected]) {
        this.tax1Label.patchValue(labelsTax[this.countryCodeSelected]);
        this.taxOptions = this.showWebToCaseFormLink(labelsTax[this.countryCodeSelected]);
        this.selectedTax = this.taxOptions[0];
      }
    });
  }

  disableSelectTax(){
    if (this.taxOptions.length === 1) {
      this.taxSelect.disable();
    }else{
      this.taxSelect.enable();
    }
  }

  settaxSelect(value: string) { this.taxSelect.patchValue(value); }

  disablePendingFields() {

    if (this.pendingFieldsCard) {
      this.pendingFieldsCard.forEach(pendingField => {
        if (this.taxForm.get(pendingField)) {
          this.disableControl(this.taxForm.get(pendingField), this.taxForm.get(pendingField).value);
        }
      });
    }
  }

  private disableControl(control: AbstractControl, value: string) {
    control.patchValue(value);
    control.disable();
  }

  isPendingReview(field: string) {
    if (this.pendingFieldsCard) {
      return this.pendingFieldsCard.indexOf(field) !== -1;
    }
  }

  close() {
    this.closeModalFromChild.emit();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  showWebToCaseFormLink(labelTaxList:  string[]) {
    const copiedLabelTaxList = [...labelTaxList];
    const sirenKey: number = copiedLabelTaxList.indexOf('SIREN');

    if (Constants.countryWithACN.indexOf(this.countryCodeSelected) !== -1) {
      this.showACNLink = true;
    }

    if (sirenKey !== -1) {
      copiedLabelTaxList.splice(sirenKey, 1);
      this.showSIRENLink = true;
    }

    return copiedLabelTaxList;
  }

}
