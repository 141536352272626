import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/app.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfo } from '@core/models/user-info.model';
import { CommonDataService } from '@core/services';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { TitleService } from '@core/services/title.service';
import { TranslateService } from '@ngx-translate/core';
import { ExpirationModalComponent } from '@shared/components/expiration-modal/expiration-modal.component';
import { ProfileHeaderComponent } from '@shared/layout/profile-header/profile-header.component';
import { environment } from 'environments/environment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'ta-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {

  clientDefenseUrl: string = Constants.URL_CLIENT_DEFENSE;
  paymentEntityBankOfSpainUrl: string = Constants.URL_BANK_OF_SPAIN;
  legalNoticeUrl: string = Constants.URL_LEGAL_NOTICE;
  privacyPolicyUrl: string = Constants.URL_ONYX_PRIVACY_POLICY;
  termsOfBusinessUrl: string;
  private termsOfBusiness: string = Constants.URL_TERMS_OF_BUSINESS;

  // componentTitle: string;
  username: string;
  isUserLoggedIn: boolean;

  userInfo: UserInfo;

  langOptions = Constants.LANGUAGE_OPTIONS;
  language: string;

  bsModalRef: BsModalRef;

  expirationTitle: string;
  expirationContent: string;
  countDown: number;
  isExpModalOpen = false;

  private readonly sessionExpireSeconds: number = Constants.SESSION_EXPIRE_WARNING_SECONDS;

  subscription: Subscription;

  @ViewChild(ProfileHeaderComponent) profileHeader;

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    if (this.profileHeader) {
      this.profileHeader.resetPopoverIsOpen();
    }
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private profileService: TaprofileUtilsService,
    private commonDataService: CommonDataService,
    private titleService: TitleService,
    private modalService: BsModalService,
    private cdRef: ChangeDetectorRef,
    private jwtHelper: JwtHelperService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

  }

  ngOnInit(): void {
    this.setLanguage();
    this.titleService.setPageTitle();

    this.subscription = new Subscription();

    this.subscription.add(
      this.authService.isLoggedIn.subscribe(value => {
        this.isUserLoggedIn = value;
        if (value) {
          this.commonDataService.loadTaxLabels().pipe().subscribe();
        }
      }));

    this.subscription.add(
      this.profileService.userInfo.subscribe(userInfo => {
        this.userInfo = userInfo;
        this.username = userInfo ? userInfo.email : '';

        if (userInfo) {
          this.language = userInfo.config.language;
        } else if (
          localStorage.getItem(Constants.LANG_KEY) && localStorage.getItem(Constants.LANG_KEY) != null
          && this.langOptions.find(x => x.code === localStorage.getItem(Constants.LANG_KEY))) {
          this.language = localStorage.getItem(Constants.LANG_KEY);
        } else {
          this.language = 'en';
        }

        this.termsOfBusinessUrl = this.termsOfBusiness.replace('@LANGUAGE@',
          Constants.LANGUAGE_OPTIONS.find(lang => lang.code === this.language).gecoCode);

        localStorage.setItem(Constants.LANG_KEY, this.language);
        this.setLanguage();

      }));

    this.subscription.add(
      this.commonDataService.expModal.subscribe(expModal => {
        this.isExpModalOpen = expModal;
      }));

    this.subscription.add(
      timer(0, 1000).subscribe(() => {

        if (this.isUserLoggedIn) {
          let diff;
          if (!this.jwtHelper.isTokenExpired()) {
            diff = Date.parse(this.jwtHelper.getTokenExpirationDate().toString()) - Date.parse(new Date().toString());
          } else {
            diff = 0;
          }
          const seconds = Math.max(Math.floor(diff / 1000), 0);

          if (seconds <= this.sessionExpireSeconds) {
            this.countDown = seconds;
            this.openModal();
          }
        }

      })
    );


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.closeModal();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  signOut() {
    this.titleService.setPageTitle('');
    this.authService.logout();
    this.router.navigate(['auth/login']);
  }

  setLanguage() {

    let langStored: string;

    if (localStorage.getItem(Constants.LANG_KEY) && localStorage.getItem(Constants.LANG_KEY) != null) {
      langStored = localStorage.getItem(Constants.LANG_KEY);
    }

    if (this.langOptions.find(x => x.code === langStored) && langStored != null) {
      this.translate.use(langStored);
    } else {
      if (this.langOptions.find(x => x.code === this.translate.getBrowserLang())) {
        localStorage.setItem(Constants.LANG_KEY, this.translate.getBrowserLang());
      } else {
        localStorage.setItem(Constants.LANG_KEY, this.translate.getDefaultLang());
      }
      this.translate.use(localStorage.getItem(Constants.LANG_KEY));
    }
  }

  openModal() {
    if (!this.isExpModalOpen) {
      const modalOptions: any = {
        class: 'modal-dialog-centered',
        initialState: {
          countDown: this.countDown,
        },
        backdrop: 'static',
        keyboard: false,
      };

      this.bsModalRef = this.modalService.show(ExpirationModalComponent, modalOptions);
      this.commonDataService.expModal.next(true);
    }
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  getUserGuide(event: Event): void {
    if (event) {
      window.open(environment.cdnUrl + Constants.USER_GUIDE_NAME, '_blank');
    }
  }
}
