import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { City, CityList, Country, Dropdown, State } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Constants } from '../../app.constants';
import { LabelTax } from '@core/models/Label-tax.model';
import { Currency } from '@core/models/currency.model';

export const countryEndpoint = Constants.URL_GET_COUNTRIES;
export const statesByCountryEndpoint = Constants.URL_GET_STATES_BY_COUNTRY;
export const citiesBySateEndpoint = Constants.URL_GET_STATES_BY_STATE;
export const labelsTinEndpoint = environment.apiUrl + 'labels/tin';
export const currencyEndpoint = environment.apiUrl + 'currencies/all';

@Injectable({
  providedIn: 'root',
})
export class CommonDataService {

  private countries: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  readonly countries$: Observable<Dropdown[]> = this.countries.asObservable();

  private states: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  readonly states$: Observable<Dropdown[]> = this.states.asObservable();

  private cities: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  readonly cities$: Observable<Dropdown[]> = this.cities.asObservable();

  public expModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly expModal$: Observable<boolean> = this.expModal.asObservable();

  private labelsTax$: BehaviorSubject<LabelTax[]> = new BehaviorSubject<LabelTax[]>([]);

  private currencies: BehaviorSubject<Dropdown[]> = new BehaviorSubject([]);
  readonly currencies$: Observable<Dropdown[]> = this.currencies.asObservable();

  constructor(
    private httpClient: HttpClient
  ) { }

  getCountries(): Observable<Country[]> {
    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    const payload = { };
    const url = countryEndpoint;
    return this.httpClient.post<Country[]>(url, payload, requestOptions);
  }

  loadCountries(): Observable<Dropdown[]> {
    return this.getCountries().pipe(
      map((countryList: Country[]) => countryList.map(country => new Dropdown(country.countryName, country.countryId))),
      map((countries: Dropdown[]) => {
        this.countries.next(countries);
        return countries;
      })
    );
  }


  hasCountryStates(country: string): boolean {
    return Constants.countriesWithStates.indexOf(country) !== -1;
  }

  getStatesByCountry(countryCode: string): Observable<State[]> {
    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    const payload = { };
    let url = statesByCountryEndpoint;
    url = url.replace('{country}', countryCode);
    return this.httpClient.post<State[]>(url, payload, requestOptions);
  }

  loadStatesByCountry(countryCode: string): Observable<Dropdown[]> {
    return this.getStatesByCountry(countryCode).pipe(
      shareReplay(1),
      map((stateList: State[]) => {
        const options = stateList.map(state => new Dropdown(state.stateName, state.stateId));
        return options;
      }));
  }

  getCitiesByCountryAndState(countryCode: string, stateId: string): Observable<City[]> {
    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    const payload = { };
    let url = citiesBySateEndpoint;
    url = url.replace('{country}', countryCode);
    url = url.replace('{state}', stateId);
    return this.httpClient.post<City[]>(url, payload, requestOptions);
  }

  loadCitiesByCountryAndState(countryCode: string, stateId: string): Observable<Dropdown[]> {
    return this.getCitiesByCountryAndState(countryCode, stateId).pipe(
      shareReplay(1),
      map((stateList: City[]) => {
        const options = stateList.map(city => new Dropdown(city.name, city.id));
        return options;
      }));
  }

  getTaxLabels(): Observable<LabelTax[]> {
    return this.httpClient.get<LabelTax[]>(labelsTinEndpoint);
  }

  loadTaxLabels(): Observable<LabelTax[]> {
    return this.getTaxLabels().pipe(tap(labelsTax => {
      this.labelsTax$.next(labelsTax);
    }));
  }

  get labelTax(): Observable<LabelTax[]> {
    return this.labelsTax$.asObservable();
  }

  getCountryById(countryId: string): Dropdown {
    return (this.countries.value).find(i => i.code === countryId);
  }

  getStateById(stateId: string): Dropdown {
    return (this.states.value).find(i => i.code === stateId);
  }

  getCurrencies(): Observable<Currency[]> {
    return this.httpClient.get<Currency[]>(currencyEndpoint);
  }

  loadCurrencies(): Observable<Dropdown[]> {
    return this.getCurrencies().pipe(
      map((currencyList: Currency[]) => currencyList.map(currency => new Dropdown(currency.name, currency.code))),
      map((currencies: Dropdown[]) => {
        this.currencies.next(currencies);
        return currencies;
      })
    );
  }

}
