import { Component, Input, OnInit, ɵConsole } from '@angular/core';
import { Agency } from '@core/models';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { CommonDataService } from '@core/services';

@Component({
  selector: 'ta-vat-default-card',
  templateUrl: './vat-default-card.component.html',

})
export class VatDefaultCardComponent implements OnInit {
  @Input() agency: Agency;

  label: string;
  vat: string;
  secondaryLabel: string;
  secondaryVat: string;

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private commonService: CommonDataService
  ) { }

  ngOnInit() {

    this.taProfileUtilsService.agencyInfo.subscribe(agencyInfo => {
      if (agencyInfo && agencyInfo.newInfo && agencyInfo.newInfo.legal) {

        this.label = null;
        this.vat = null;
        this.secondaryLabel = null;
        this.secondaryVat = null;

        this.commonService.labelTax.subscribe(labelsTax => {
          if (labelsTax[agencyInfo.newInfo.legal.countryCode]) {
            if (labelsTax[agencyInfo.newInfo.legal.countryCode][0]) {
              this.label = labelsTax[agencyInfo.newInfo.legal.countryCode][0];
            }
            if (labelsTax[agencyInfo.newInfo.legal.countryCode][1]) {
              this.secondaryLabel = labelsTax[agencyInfo.newInfo.legal.countryCode][1];
            }
          }

          const primaryTaxLabel = agencyInfo.newInfo.legal.primaryTaxLabel;
          if (primaryTaxLabel) {
            this.label = agencyInfo.newInfo.legal.primaryTaxLabel;
            this.vat = agencyInfo.newInfo.legal.primaryTax;
          }

          const secondaryTaxLabel = agencyInfo.newInfo.legal.secondaryTaxLabel;
          if (secondaryTaxLabel) {
            this.secondaryLabel = agencyInfo.newInfo.legal.secondaryTaxLabel;
            this.secondaryVat = agencyInfo.newInfo.legal.secondaryTax;
          }
        });
      }
    });
  }

}
