<ods-alert-inline *ngIf="profileChangesPending" type="info"
  componentTitle="{{'pendingChanges.title' | translate}}">
  {{'pendingChanges.review' | translate}}
  <a aria-selected='false' href='#/profile-history'>{{'pendingChanges.profileTxt' | translate}}</a>
  {{'pendingChanges.details' | translate}}
</ods-alert-inline>

<ods-alert-inline *ngIf="taxValidationStatus===taxStatusFailed" type="danger"><i class="far fa-exclamation-triangle"></i> {{'taxValidation.verificationFailedIRS' | translate}}</ods-alert-inline>

<p>
  {{'missingInformation' | translate}}
</p>

<div class="row">
  <div class="col-lg-4">
    <!-- Legal Details -->
    <ta-legal-details-card [newInfo]="newInfo" [legalDetails]="legal" [address]="legal" [pendingFields]="pendingFields" [selectedProfile]="selectedProfile">
    </ta-legal-details-card>
  </div>

  <div class="col-lg-8">
    <div class="card-deck">
      <!-- Commercial Details -->
      <div class="card">
        <ta-address-card [newInfo]="newInfo" [type]="'commercial'" [address]="commercial" [pendingFields]="pendingFields" [selectedProfile]="selectedProfile"></ta-address-card>
      </div>
      <!-- Postal Address -->
      <div class="card">
        <ta-address-card [newInfo]="newInfo" [type]="'postal'" [address]="postal" [pendingFields]="pendingFields" [selectedProfile]="selectedProfile">
        </ta-address-card>
      </div>
    </div>

    <!-- Contacts -->
    <ta-contact-card [content]="contacts" [taCode]="newInfo?.taCode" [pendingFields]="pendingFields"></ta-contact-card>
  </div>
</div>