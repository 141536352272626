import { Component, Input, OnInit } from '@angular/core';
import { Address, Agency, Dropdown, Profile } from '@core/models';
import { CommonDataService, TaprofileUtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Constants } from '../../../../app.constants';
import { AddressModalComponent } from '../address-modal/address-modal.component';

@Component({
  selector: 'ta-address-card',
  templateUrl: './address-card.component.html',
})

export class AddressCardComponent implements OnInit {

  @Input() newInfo: Agency;
  @Input() selectedProfile: Profile;

  private _pendingFields: string[];
  
  invalidName: boolean = false;

  get pendingFields(): string[] {
    return this._pendingFields;
  }

  @Input()
  set pendingFields(pendingFields: string[]) {
    this._pendingFields = pendingFields;
    this.loadOptions();
    this.setCardDetails(this.pendingFields);
  }

  /** type */
  private _type: string;

  get type(): string {
    return this._type;
  }

  @Input()
  set type(type: string) {
    this._type = type;

  }

  /** address */
  private _address: Address;

  get address(): Address {
    return this._address;
  }

  @Input()
  set address(address: Address) {
    // If country dont have state load noState constants
    if (address && address.countryCode && !this.commonDataService.hasCountryStates(address.countryCode)) {
      address.stateCode = Constants.noStates;
      address.stateName = Constants.NO_STATES_NAME;
    }

    this._address = address;
    this.isAddressEmpty = this.taProfileUtilsService.isEmpty(this._address);
  }

  bsModalRef: BsModalRef;

  title: string;
  modalTitle: string;
  isCommercial: boolean;
  isAddressEmpty: boolean;
  states$: Observable<Dropdown[]>;
  cities$: Observable<Dropdown[]>;
  pendingFieldsCard: string[] = [];

  public disableUpdateButton: boolean;
  public updateButtonTooltipText: string;

  constructor(
    private modalService: BsModalService,
    private translate: TranslateService,
    private commonDataService: CommonDataService,
    private taProfileUtilsService: TaprofileUtilsService
  ) { }

  ngOnInit() {
  }

  loadOptions() {
    this.states$ = null;
    this.cities$ = null;
    if (this._address && this._address.countryCode) {
      this.states$ = this.commonDataService.loadStatesByCountry(this._address.countryCode);
      this.states$.pipe(take(1)).subscribe();
      let stateId = this._address.stateCode;
      if (!this.commonDataService.hasCountryStates(this._address.countryCode)) {
        stateId = Constants.noStates;
      }
      this.cities$ = this.commonDataService.loadCitiesByCountryAndState(this._address.countryCode, stateId);
      this.cities$.pipe(take(1)).subscribe();
    }
  }

  setCardDetails(pendingFields: string[]) {
    this.pendingFieldsCard = this.getPendingFieldsCard(pendingFields, this.type);

    if (this.selectedProfile && (this.selectedProfile.member === 'RP' || this.selectedProfile.member === 'SP')) {
      this.disableUpdateButton = true;
      this.updateButtonTooltipText = this.translate.instant('messages.needAssistancePageRequired');
    }else{
      this.disableUpdateButton = false;
      this.updateButtonTooltipText = '';
    }

    switch (this.type) {
      case 'commercial':
        this.title = this.translate.instant('taAddressCardCommercial.title');
        this.modalTitle = 'taAddressCardCommercial.title';
        this.isCommercial = true;
        break;
      case 'postal':
        this.title = this.translate.instant('taAddressCardPostal.title');
        this.modalTitle = 'taAddressCardPostal.title';
        this.isCommercial = false;
        break;
      default:
        break;
    }
  }

  openModal() {
    const modalOptions: any = {
      class: 'modal-lg',
      initialState: {
        newInfo: this.newInfo,
        address: this.address,
        title: this.translate.instant(this.modalTitle),
        isCommercial: this.isCommercial,
        states$: this.states$,
        cities$: this.cities$,
        pendingFieldsCard: this.pendingFieldsCard,
        card: this.type,
      },
    };
    this.bsModalRef = this.modalService.show(AddressModalComponent, modalOptions);
  }

  getPendingFieldsCard(pendingFields: string[], type: string) {
    const pendingFieldsRet: string[] = [];
    const mappingFields = Constants.MAPPING_PENDING_FIELDS;
    if (pendingFields) {
      this._pendingFields.forEach(pendingField => {
        const fieldList = mappingFields.filter(item => item.cardName === type && item.pendingField === pendingField);
        fieldList.forEach(field => {
          pendingFieldsRet.push(field.cardField);
        });
      });

    }

    return pendingFieldsRet;

  }

}
