<form [formGroup]="taxForm">

  <div class="form-group row" [class.form-group-invalid]="!isValidVat">
    <label class="{{labelColumnCssClasses}} col-form-label">{{'legalDefaultTax.tax_type_label' | translate}}</label>
    <div class="{{fieldColumnCssClasses}}">
      <div class="row form-control-segments">
        <div class="col-3">
          <select class="form-control" formControlName="taxSelect" [(ngModel)]="selectedTax">
            <option *ngFor="let tax of taxOptions" [value]="tax" [selected]="tax === taxOptions[0]">{{tax}}</option>
          </select>
        </div>
        <div class="col-6">

          <input type="text" class="form-control" [class.is-invalid]="isInvalid(tax1)"
            [class.is-invalid]="!isValidVat" formControlName="tax1" name="tax1"
            placeholder="{{'legalDefaultTax.TaxLabel1AllPlaceholder' | translate}}" required/>
          <div *ngIf="tax1.invalid" class="invalid-feedback">
            <span>{{isInvalidMessage(tax1) }}</span>
          </div>
          <div *ngIf="!isValidVat" class="invalid-feedback d-block">
            <span>{{'legalDefaultTax.invalidVat' | translate}}</span>
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="pendingReviewHelpTex;context:{pendingField: 'tax1'}"></ng-container>
      <ng-container *ngTemplateOutlet="tax2HelpTex"></ng-container>
    </div>
  </div>
</form>

<ng-template #pendingReviewHelpTex let-pendingField=pendingField>
  <small *ngIf="isPendingReview(pendingField)" class="form-text text-muted">
    {{'pendingChanges.profileTxt1' | translate}}
    <a href="#/profile-history" class="alert-link"
      (click)="close()">{{'pendingChanges.profileTxt' | translate}}</a>
    {{'pendingChanges.profileTxt2' | translate}}
  </small>
</ng-template>


<ng-template #tax2HelpTex>
  <small *ngIf="isTax2LinkShow" class="form-text text-muted">
    {{'legalDefaultTax.tax2BlockLinkText1' | translate}}
    <a href="#" class="alert-link"
      (click)="showTax2Block()">{{'legalDefaultTax.tax2BlockLinkText2' | translate}}</a>
    {{'legalDefaultTax.tax2BlockLinkText3' | translate}}
  </small>
  <small *ngIf="showACNLink" class="form-text text-muted">
    {{'legalDefaultTax.AUBlockLinkText' | translate}} <a href="https://onyxcentersource.my.site.com/p/s/web-to-case-form" target="_blank"><i class='far fa-external-link'></i></a>
  </small>
  <small *ngIf="showSIRENLink" class="form-text text-muted">
    {{'legalDefaultTax.FRBlockLinkText' | translate}} <a href="https://onyxcentersource.my.site.com/p/s/web-to-case-form" target="_blank"><i class='far fa-external-link'></i></a>
  </small>
</ng-template>