import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from '@app/app.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Agency, AgencyInfo, GecoErrorMessage, GecoResponse, Profile, UserInfo } from '@core/models';
import { ProfileHistory } from '@core/models/profile-history.model';
import { SettingsInfo } from '@core/models/settings-info.model';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MessagesUtilsService } from './messages-utils.service';
import { SpinnerUtilsService } from './spinner-utils.service';

@Injectable({
  providedIn: 'root',
})
export class TaprofileUtilsService {

  private userInfo$: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  private agencyInfo$: BehaviorSubject<AgencyInfo> = new BehaviorSubject<AgencyInfo>(null);
  private profiles$: BehaviorSubject<Profile[]> = new BehaviorSubject<Profile[]>([]);
  private profileHistoryAudit$: BehaviorSubject<ProfileHistory[]> = new BehaviorSubject<ProfileHistory[]>([]);
  private settings$: BehaviorSubject<SettingsInfo> = new BehaviorSubject<SettingsInfo>(null);

  jwtHelper = new JwtHelperService();

  private profiles = new BehaviorSubject<Profile[]>(null);
  userProfiles = this.profiles.asObservable();

  private currentProfile = new BehaviorSubject<Profile>(null);
  selectedProfile = this.currentProfile.asObservable();

  private currentIsAgencyGroup = new BehaviorSubject<boolean>(false);
  selectedProfileIsAgencyGroup = this.currentIsAgencyGroup.asObservable();

  private isValidVat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isValidVat$ = this.isValidVat.asObservable();

  private isValidTax: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isValidTax$ = this.isValidTax.asObservable();

  private isValidLNM: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isValidLNM$ = this.isValidLNM.asObservable();

  private showVCCPopover: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showVCCPopover$ = this.showVCCPopover.asObservable();

  private currentLegalcountryCode = new BehaviorSubject<string>(null);
  selectedLegalCountrycode = this.currentLegalcountryCode.asObservable();

  constructor(
    private httpClient: HttpClient,
    private messageService: MessagesUtilsService,
    private spinnerService: SpinnerUtilsService
  ) { }

  getProfiles(): Observable<Profile[]> {
    if (localStorage.getItem(Constants.TOKEN_KEY)) {
      return this.httpClient.get<Profile[]>(Constants.URL_USER_PROFILE_LIST).pipe(tap((profiles: Profile[]) => {
        this.profiles$.next(profiles);
        return profiles;
      }));
    } else {
      return EMPTY;
    }
  }

  get profiless(): Observable<Profile[]> {
    return this.profiles$.asObservable();
  }

  setIsValidVat(value: boolean) {
    this.isValidVat.next(value);
  }

  setIsValidTax(value: boolean) {
    this.isValidTax.next(value);
  }

  setIsValidLNM(value: boolean) {
    this.isValidLNM.next(value);
  }

  loadUserInfo(): Observable<UserInfo> {
    return this.getUserInfo();
  }

  getUserInfo(): Observable<UserInfo> {
    this.spinnerService.setMessage('spinner.loadingSettings');
    return this.httpClient.get<UserInfo>(Constants.URL_USER_INFO).pipe(
      tap((userInfo: UserInfo) => {
        // Get language from userInfo instead of setting it to 'en'
        userInfo.config.language = Constants.LANGUAGE_OPTIONS.find(lang => lang.gecoCode === userInfo.config.language).code;
        this.userInfo$.next(userInfo);
        return userInfo;
      }),
      catchError(error => {
        this.userInfo$.next(null);
        return EMPTY;
      })
    );
  }

  get userInfo(): Observable<UserInfo> {
    return this.userInfo$.asObservable();
  }

  clearUserInfo() {
    this.userInfo$.next(null);
  }

  setSelectedProfile(profiles: Profile[], id: string): Profile {
    this.currentProfile.next(profiles.find(profile => profile.id === id));
    this.currentIsAgencyGroup.next(this.currentProfile.value.code.slice(0, 4) === 'PSLA');
    return this.currentProfile.value;
  }

  loadAgencyInfo(): Observable<AgencyInfo> {
    return this.getAgencyInfo();
  }

  getAgencyInfo(): Observable<AgencyInfo> {
    this.spinnerService.setMessage('spinner.loadingProfile');
    return this.httpClient.get<AgencyInfo>(Constants.URL_AGENCY_RETRIEVE).pipe(
      tap((agencyInfo: AgencyInfo) => {
        this.agencyInfo$.next(agencyInfo);
        return agencyInfo;
      }),
      catchError(error => {
        this.agencyInfo$.next(null);
        return EMPTY;
      })
    );
  }

  get agencyInfo(): Observable<AgencyInfo> {
    return this.agencyInfo$.asObservable();
  }

  setCurrentProfile(id: string, code: string) {
    this.spinnerService.setMessage('spinner.loadingProfile');
    return this.httpClient.post(Constants.URL_PROFILE_CHANGE, { newProfile: id, newCode: code });
  }

  saveRequest(info: AgencyInfo) {
    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinnerService.setMessage('spinner.savingProfile');
    return this.httpClient.post(Constants.URL_SAVE_REQUEST, info, requestOptions);
  }

  saveNewInfo(newInfo: Agency) {
    const agencyInfo = this.agencyInfo$.value;
    agencyInfo.newInfo = newInfo;
    return this.saveRequest(agencyInfo);
  }

  reloadAgencyInfo(agencyInfo: AgencyInfo) {
    return this.agencyInfo$.next(agencyInfo);
  }

  loadProfileHistoryAudit(): Observable<ProfileHistory[]> {
    return this.getProfileHistoryAudit();
  }

  getProfileHistoryAudit(): Observable<ProfileHistory[]> {
    this.spinnerService.setMessage('spinner.loadingProfileHistory');
    return this.httpClient.get<ProfileHistory[]>(Constants.URL_AUDIT_RETRIEVE).pipe(tap((profileHistoryAudit: ProfileHistory[]) => {
      const hideField = [
        'CITYCODE', 'STATECODE', 'COUNTRYCODE',
        'POSTALCITYCODE', 'POSTALSTATECODE', 'POSTALCOUNTRYCODE',
        'LEGALCITYCODE', 'LEGALSTATECODE', 'LEGALCOUNTRYCODE',
        'CIF', 'TAXID', 'EIN', 'SSN', 'LASTUPDATE', 'OTHERBUSINESSTYPE', 'TACODE', 'PRIMARYTAXTYPE', 'SECONDARYTAXTYPE',
      ];

      if (this.agencyInfo$.value && this.agencyInfo$.value.newInfo.legal.stateCode === '99') {
        hideField.push('LEGALSTATEDSP');
      }
      if (this.agencyInfo$.value && this.agencyInfo$.value.newInfo.commercial.stateCode === '99') {
        hideField.push('STATEDSP');
      }
      if (this.agencyInfo$.value && this.agencyInfo$.value.newInfo.postal.stateCode === '99') {
        hideField.push('POSTALSTATEDSP');
      }

      if (this.agencyInfo$.value && this.agencyInfo$.value.newInfo.legal.countryCode !== 'US') {
        hideField.push('PRIMARYTAXFROM');
        hideField.push('SECONDARYTAXFROM');
        hideField.push('EXEMPTBW');
        hideField.push('BUSINESSTYPE');
      }
      const profileHistoryAuditShow = profileHistoryAudit.filter(item => !(hideField.indexOf(item.field) > -1));

      profileHistoryAuditShow.forEach(item => {
        this.cleanUndefinedValue(item);
      });
      this.profileHistoryAudit$.next(profileHistoryAuditShow);
      return profileHistoryAuditShow;
    }));
  }

  private cleanUndefinedValue(item: ProfileHistory) {
    if (item.field === 'STATEDSP' || item.field === 'POSTALSTATEDSP' || item.field === 'LEGALSTATEDSP') {
      if (item.oldValue === Constants.NO_STATES_NAME) {
        item.oldValue = null;
      }
      if (item.newValue === Constants.NO_STATES_NAME) {
        item.newValue = null;
      }
    }
  }

  get profileHistoryAudit(): Observable<ProfileHistory[]> {
    return this.profileHistoryAudit$.asObservable();
  }

  validateLegalData(country: string, state: string, cityName: string, zipcode: string, vatCode: string, taxCode: string, legalName: string, commercialName: string, taxName: string):
    Observable<GecoResponse> {

    const formData = new FormData();
    formData.append('pToken_in', localStorage.getItem(Constants.TOKEN_KEY));
    formData.append('pCountry_in', country);
    formData.append('pState_in', state);
    formData.append('pCityName_in', cityName);
    formData.append('pZipcode_in', zipcode);
    formData.append('pVatCode_in', vatCode);
    formData.append('pTaxCode_in', taxCode);
    formData.append('pLegalName_in', legalName);
    formData.append('pTaxName_in', taxName);
    formData.append('pCommercialName_in', commercialName);

    this.spinnerService.setMessage('spinner.validatingLegalData');
    return this.httpClient.post(Constants.URL_VALIDATE_LEGAL_DATA, formData).pipe(tap((response: GecoResponse) => {
      // Read error messages list
      if ((response.errorList as GecoErrorMessage[])) {  // Type validation.
        const errorList: GecoErrorMessage[] = response.errorList as GecoErrorMessage[];
        let messageType;

        this.resetValidations();

        errorList.forEach(item => {
          messageType = 'danger';

          if (item.message.startsWith('ERROR_VAT')) {
            this.isValidVat.next(false);
          } else if (item.message.startsWith('ERROR_TAX')) {
            this.isValidTax.next(false);
          } else if (item.message.startsWith('ERROR_LNM') && item.message !== 'ERROR_LNM_0005') {
            this.isValidLNM.next(false);
          } else {
            messageType = 'warning';
          }

          this.messageService.addMessage({
            type: messageType,
            icon: '',
            code: item.message,
            errorObject: response,
          });

        });

      }
    }));
  }

  private resetValidations() {
    this.isValidVat.next(true);
    this.isValidTax.next(true);
    this.isValidLNM.next(true);
  }

  get settings(): Observable<any> { return this.settings$.asObservable(); }

  saveSettings(settingsInfo: SettingsInfo) {
    const headerDict = {
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.spinnerService.setMessage('spinner.savingSettings');
    return this.httpClient.post<GecoResponse>(Constants.URL_SAVE_USER_SETTINGS, settingsInfo, requestOptions)
      .pipe(tap((response: GecoResponse) => {
        if (response.successful) {
          this.settings$.next(settingsInfo);


          settingsInfo.userInfo.config.language = Constants.LANGUAGE_OPTIONS.find(lang =>
            lang.gecoCode === settingsInfo.userInfo.config.language).code;

          this.userInfo$.next(settingsInfo.userInfo);
        }
        return response;
      }));
  }

  savePassword(appToken: string, appKey: string, emailToken: string, id: number, password: string) {

    const formData = new FormData();
    formData.append('pToken_in', appToken);
    formData.append('pAppKey_in', appKey);
    formData.append('pEmailToken_in', emailToken);
    formData.append('pId_in', id.toString());
    formData.append('pNewPassword_in', password);

    this.spinnerService.setMessage('spinner.savingSettings');
    return this.httpClient.post(Constants.URL_CHANGE_PASSWORD, formData).pipe(tap((response: GecoResponse) => {
      if (!response.successful && (response.error as GecoErrorMessage).message) {  // Type validation.
        const error: GecoErrorMessage = response.error as GecoErrorMessage;
        this.messageService.addMessage({
          type: 'error',
          icon: '',
          code: error.message,
          errorObject: response,
        });
      }
      return response;
    }));

  }

  validateRecoveryPasswordToken(appKey: string, emailToken: string) {

    const formData = new FormData();
    formData.append('pAppKey_in', appKey);
    formData.append('pToken_in', emailToken);

    return this.httpClient.post(Constants.URL_VALIDATE_RECOVERY_PASSWORD_TOKEN, formData).pipe(tap((response: GecoResponse) => {
      return response;
    }));

  }

  setSelectedLegalCountryCode(countryCode: string) {
    this.currentLegalcountryCode.next(countryCode);
  }

  getSelectedLegalCountryCode(): Observable<string> {
    return this.currentLegalcountryCode.asObservable();
  }

  isEmpty(obj: any): boolean {
    let isEmpty = true;
    if (obj) {
      for (const key of Object.keys(obj)) {
        if (obj[key]) {
          isEmpty = false;
          break;
        }
      }
    }
    return isEmpty;
  }

  validateRegistration(token: string) {

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': token,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.post(Constants.URL_EMAIL_CONFIRMATION, null, requestOptions).pipe(tap((response: string) => {
      return response;
    }));

  }

  getFirstLogin(): Observable<boolean> {
    return this.httpClient.get(Constants.URL_USER_FIRSTLOGIN).pipe(tap((response: boolean) => {
      return response;
    }));
  }

  setShowVCCPopover(value: boolean) {
    this.showVCCPopover.next(value);
  }

  getShowVCCPopover(): Observable<boolean> {
    return this.showVCCPopover.asObservable();
  }

  getVccPending(): Observable<boolean> {
    return this.httpClient.get(Constants.URL_USER_VCC_PENDING).pipe(tap((response: boolean) => {
      return response;
    }));
  }

  isVccAllowed (countryCode: string): boolean {
    return Constants.VCC_ALLOWED_COUNTRY_LIST.some ( list => list.country === countryCode);
  }


}
