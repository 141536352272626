<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-lg-7">
        <h2 class="card-title"> {{'taLegalDetailsCard.title' | translate}} </h2>
      </div>
      <div class="col-lg-5 text-lg-right">
        <button class="btn btn-sm btn-secondary text-nowrap" (click)="openModal()" data-toggle="modal" [disabled]="disableUpdateButton" [title]="updateButtonTooltipText">
          <i class="far fa-pencil"></i>
          {{'common.updateBtn' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div *ngIf="!isLegalDetailsEmpty; else emptyCard" class="form-text">
      <div>        
        <ods-alert-inline *ngIf="taxValidationStatus===taxStatusFailed" type="danger"><i class="far fa-exclamation-triangle"></i> {{'taxValidation.verificationFailed' | translate }} </ods-alert-inline>
        <ods-alert-inline *ngIf="taxValidationStatus===taxStatusUnverified" type="warning"><i class="far fa-exclamation-triangle"></i> {{'taxValidation.verificationPending' | translate}}</ods-alert-inline>
        <p><label>{{'taLegalDetailsCard.titleDetails' | translate}}</label>&nbsp; 
          <span *ngIf="taxValidationStatus===taxStatusUnverified" class="badge badge-warning">{{'taxValidation.badgeUnverified' | translate}}</span>           
          <span *ngIf="taxValidationStatus===taxStatusVerified" class="badge badge-success">{{'taxValidation.badgeVerified' | translate}}</span>                   
        </p>        
        <ta-address-block [address]="legalDetails" [invalidName]="taxValidationStatus===taxStatusFailed"></ta-address-block>
        <span class="text-caption">{{'taLegalDetailsCard.checksAlert' | translate}}</span>
      </div>
      <br>
      <div [ngSwitch]="legalType" *ngIf="legalType">
        <ta-tin-card *ngSwitchCase="'TIN'" [agency]="agencyInfo"></ta-tin-card>
        <ta-vat-default-card *ngSwitchDefault [agency]="agencyInfo"></ta-vat-default-card>
      </div>
    </div>
    <ng-template #emptyCard>
      <ods-alert-inline *ngIf="isLegalDetailsEmpty" type="danger"><i class="far fa-exclamation-triangle"></i> {{'taxValidation.noLegalDetailsProvided' | translate }}</ods-alert-inline>
      <ta-empty-card [card]="'legal'" [newInfo]="newInfo"></ta-empty-card>
    </ng-template>
  </div>
  <div class="card-footer" *ngIf="lastUpdate">
    <span class="text-caption">
      {{'taLegalDetailsCard.footer' | translate}} {{ lastUpdate | userDate}}
    </span>
  </div>
</div>