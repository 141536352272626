import { Component, Input, OnInit } from '@angular/core';
import { Address } from '@core/models';
import { Constants } from 'app/app.constants';

@Component({
  selector: 'ta-address-block',
  templateUrl: './address-block.component.html',
})
export class AddressBlockComponent implements OnInit {

  private _address: Address;  

  get address() {
    return this._address;
  }

  @Input()
  set address(address: Address) {
    this._address = address;
  }

  private _invalidName: boolean;

  get invalidName() {
    return this._invalidName;
  }

  @Input()
  set invalidName(invalidName: boolean) {
    this._invalidName = invalidName;
  }

  constructor() { }

  ngOnInit() {    
  }

  validStateName(stateName: string): boolean {
    return stateName && stateName !== Constants.NO_STATES_NAME;
  }

}
