import { Component, Input, OnInit } from '@angular/core';
import { Address, Agency, Dropdown, Legal, Profile } from '@core/models';
import { CommonDataService } from '@core/services';
import { TaprofileUtilsService } from '@core/services/taprofile-utils.service';
import { Constants } from 'app/app.constants';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LegalDetailsModalComponent } from '../legal-details-modal/legal-details-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ta-legal-details-card',
  templateUrl: './legal-details-card.component.html',
})
export class LegalDetailsCardComponent implements OnInit {

  @Input() newInfo: Agency;
  @Input() selectedProfile: Profile;

  private _pendingFields: string[];

  get pendingFields(): string[] {
    return this._pendingFields;
  }

  @Input()
  set pendingFields(pendingFields: string[]) {
    this._pendingFields = pendingFields;
    this.setLegalType(this.pendingFields);
  }

  private _legalDetails: Legal;

  get legalDetails(): Legal {
    return this._legalDetails;
  }

  @Input()
  set legalDetails(legal: Legal) {
    this._legalDetails = legal;
    this.isLegalDetailsEmpty = this.taProfileUtilsService.isEmpty(this._legalDetails);
  }

  /** address */
  private _address: Address;

  get address(): Address {
    return this._address;
  }

  @Input()
  set address(address: Address) {
    this._address = address;
    this.isAddressEmpty = this.taProfileUtilsService.isEmpty(this._address);

    this.loadOptions();
  }

  bsModalRef: BsModalRef;

  title: string;
  modalTitle: string;
  isAddressEmpty: boolean;
  states$: Observable<Dropdown[]>;
  cities$: Observable<Dropdown[]>;
  pendingFieldsCard: string[] = [];
  pendingFieldsTaxCard: string[] = [];

  isLegalDetailsEmpty: boolean;

  legalType: string;
  agencyInfo: Agency;
  lastUpdate: Date;

  public disableUpdateButton: boolean;
  public updateButtonTooltipText: string;

  taxValidationStatus: string;
  public taxStatusVerified = Constants.TAX_STATUS_VERIFIED;
  public taxStatusUnverified = Constants.TAX_STATUS_UNVERIFIED;
  public taxStatusFailed = Constants.TAX_STATUS_FAILED;

  constructor(
    private taProfileUtilsService: TaprofileUtilsService,
    private modalService: BsModalService,
    private commonDataService: CommonDataService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.taProfileUtilsService.agencyInfo.subscribe(agencyInfo => {
      if (agencyInfo) {
        this.agencyInfo = agencyInfo.newInfo;
        this.lastUpdate = this.agencyInfo.lastUpdated ? new Date(Date.parse(this.agencyInfo.lastUpdated)) : null;
      }

    });
  }

  loadOptions() {
    this.states$ = null;
    this.cities$ = null;
    if (this._address && this._address.countryCode) {
      this.states$ = this.commonDataService.loadStatesByCountry(this._address.countryCode);
      this.states$.pipe(take(1)).subscribe();
      let stateId = this._address.stateCode;
      if (!this.commonDataService.hasCountryStates(this._address.countryCode)) {
        stateId = Constants.noStates;
      }
      this.cities$ = this.commonDataService.loadCitiesByCountryAndState(this._address.countryCode, stateId);
      this.cities$.pipe(take(1)).subscribe();
    }
  }

  setLegalType(pendingFields: string[]) {
    this.pendingFieldsCard = this.getPendingFieldsCard(pendingFields, 'legal');

    if (this.selectedProfile && (this.selectedProfile.member === 'RP')) {
      this.disableUpdateButton = true;
      this.updateButtonTooltipText = this.translate.instant('messages.needAssistancePageRequired');
    } else {
      this.disableUpdateButton = false;
      this.updateButtonTooltipText = '';
    }

    if (this.legalDetails) {
      switch (this.legalDetails.countryCode) {
        case Constants.countriesWithTypeTinTax:
          this.legalType = 'TIN'; // EIN or SSN
          this.pendingFieldsTaxCard = this.getPendingFieldsCard(pendingFields, 'usTax');
          this.taxValidationStatus = this._legalDetails.ooivStatus;
          break;          
        case Constants.countriesWithTypeCanadianTax:
          this.legalType = 'CAD';
          this.pendingFieldsTaxCard = this.getPendingFieldsCard(pendingFields, 'caTax');
          this.taxValidationStatus = '';
          break;
        default:
          this.legalType = 'VAT'; // can be only VAT or VAT + TAX
          this.pendingFieldsTaxCard = this.getPendingFieldsCard(pendingFields, 'defaultTax');
          this.taxValidationStatus = '';
          break;
      }
    }
  }

  openModal() {
    const modalOptions: any = {
      class: 'modal-lg',
      initialState: {
        newInfo: this.newInfo,
        address: this.address,
        states$: this.states$,
        cities$: this.cities$,
        pendingFieldsCard: this.pendingFieldsCard,
        pendingFieldsTaxCard: this.pendingFieldsTaxCard,
        card: 'legal',
      },
    };

    this.bsModalRef = this.modalService.show(LegalDetailsModalComponent, modalOptions);
  }

  getPendingFieldsCard(pendingFields: string[], type: string) {
    const pendingFieldsRet: string[] = [];
    const mappingFields = Constants.MAPPING_PENDING_FIELDS;
    if (pendingFields) {
      this._pendingFields.forEach(pendingField => {
        const fieldList = mappingFields.filter(item => item.cardName === type && item.pendingField === pendingField);
        fieldList.forEach(field => {
          pendingFieldsRet.push(field.cardField);
        });
      });

    }

    return pendingFieldsRet;
  }

}
